<template>
    <el-form-item :label="$t('calendar.note_for_employee')">
        <el-input v-model="note" type="textarea" :rows="6" :placeholder="$t('calendar.note_for_employee')" resize="none" @change="noteChanged" />
    </el-form-item>
</template>
<script>
export default {
    props: {
        modalVisible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            note: null,
        };
    },

    watch: {
        modalVisible() {
            this.note = null;
            this.$emit('change', null);
        },
    },

    methods: {
        noteChanged() {
            this.$emit('change', this.note);
        },
    },
};
</script>
